<template>
  <div class="my-auto max-w-5xl w-full mx-auto">
    <container>
      <results-display />
    </container>
  </div>
</template>

<script>
import Container from "../components/Ui/Container";
import ResultsDisplay from "../components/ResultsDisplay";
export default {
  name: "Results",
  components: { Container, ResultsDisplay },
};
</script>
