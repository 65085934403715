<template>
  <div>
    <span v-if="isLoaded" class="bg-white">
      <inline-svg
        src="/img/icon-check-fill.svg"
        class="text-green-500 fill-current w-6 h-6"
      />
    </span>
    <span v-else>
      <inline-svg src="/img/icon-loading.svg" class="w-6 h-6 opacity-40 " />
    </span>
  </div>
</template>

<script>
export default {
  name: "ResultsIndicator",
  props: {
    isLoaded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
