<template>
  <div>
    <transition
      appear
      appear-active-class="appear-from"
      appear-to-class="appear-to"
    >
      <div class=" mb-8 sm:mb-12 ">
        <h1
          class="text-2xl sm:text-4xl md:text-5xl font-headings text-center mb-0 sm:mb-2"
          v-text="$t(isLoaded ? 'title.resultsLoaded' : 'title.results')"
        />
      </div>
    </transition>

    <div class="mx-auto max-w-3xl mb-6 sm:mb-12">
      <div class="flex flex-wrap -mx-4">
        <div
          v-for="(item, index) in services"
          :key="item.id"
          class="w-full px-4"
        >
          <div
            class="py-2 lg:text-lg border-b border-white border-opacity-10 flex items-center justify-between"
          >
            {{ item.title_analyse }}
            <transition name="fade-loading">
              <results-indicator :is-loaded="resultsCompleted >= index" />
            </transition>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div
        class="p-6 z-50  sm:p-0 bg-black bg-opacity-80 sm:bg-transparent fixed sm:static left-0 right-0 bottom-0 text-center sm:mb-12 md:mb-16"
        v-if="isLoaded"
      >
        <button
          class="btn bg-red-500 hover:bg-red-600 focus:bg-red-700 text-white mx-auto w-full sm:w-auto sm:px-9"
          v-text="$t('button.view_reading')"
          @click="onSubmit"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import ResultsIndicator from "./ResultsIndicator.vue";
export default {
  name: "ResultsDisplay",
  components: { ResultsIndicator },
  data() {
    return {
      timeoutRedirect: null,
      interval: null,
      delayItemComplete: 600,
      isLoaded: false,
      results: [],
      resultsCompleted: 0,
    };
  },

  created() {
    this.results = this.resultsSaved;
  },

  mounted() {
    this.setIntervalAppear();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  computed: {
    services() {
      return this.$store.state.services;
    },

    readingComplete() {
      return this.$store.state.readingComplete;
    },
  },

  methods: {
    setIntervalAppear() {
      let i = 0;

      this.interval = setInterval(() => {
        this.resultsCompleted = i;
        i++;
        if (i === this.services.length) {
          clearInterval(this.interval);
          this.isLoaded = true;
        }
      }, this.delayItemComplete);
    },
    onSubmit() {
      this.$store.commit("updateReadingComplete", true);
      this.$router.push(this.$t("router.offer.path"));
    },
  },
  metaInfo() {
    return {
      title: this.$t(this.isLoaded ? "title.resultsLoaded" : "title.results"),
    };
  },
};
</script>
